<template>
  <nuxt-link
    class="back-button font font_sm font_bold font_uppercase"
    :class="{ 'back-button--with-icon': withIcon}"
    data-test="profile-back"
    :to="returnPath"
  >
    <slot name="icon" />
    {{ getRouteTitle }}
  </nuxt-link>
</template>
<script>
export default {
  name: 'BackButtonComponent',
  props: {
    routes: {
      type: Object,
      default: null,
    },
    route: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getRouteName() {
      if (this.routes) {
        return this.routes[this.$route.name]?.name;
      }

      return ''
    },
    getRouteParams() {
      if (this.routes) {
        return this.routes[this.$route.name]?.params;
      }

      return {};
    },
    getRouteTitle() {
      if (this.routes) {
        return this.routes[this.$route.name]?.title ?? 'Назад';
      }

      return this.route?.title ?? 'Назад'
    },
    returnPath() {
      const filteredPaths = this.$route.matched.filter(item => {
        return item.path !== this.$route.path && item.name !== this.$route.name
      })
      const backItem = filteredPaths[filteredPaths.length - 1]
      const path = backItem ? backItem.path : ''

      return {
        path: this.getRouteName ? '' : path,
        name: this.getRouteName,
        params: this.getRouteParams,
      }
    },
    withIcon() {
      return this.$slots.icon;
    },
  },
};
</script>
